import * as React from "react";
import { graphql } from "gatsby";
import ComponentTemplatePage from "../../../../components/pages/templates/ComponentTemplatePage";

const ComponentTemplate = ({ data }) => (
  <ComponentTemplatePage
    title="Form Components | UI Components Library | Uizard"
    category="Component Templates"
    url="/templates/component-templates/form/"
    metaDescription="Form UI component templates in Uizard enable you to easily add forms on your mobile and web designs. Explore Uizard components online now."
    description="
    h2:The most common forms to design your project
    <br/>
    Drag-and-drop pre-made beautiful form component template!
    <br/>
    Form component template in Uizard enable you to easily add forms on your mobile and web designs.
    No need to redesign a login form for the hundredth time! Drag-and-drop your chosen form, and enjoy Uizard's extensive styling options to make it reflect your brand identity in seconds.
    <br/>
    h3:Uizard has a growing collection of drag-and-drop form templates
    <br/>
    It's a good idea to leverage these when creating your app or website design.
    These are easy to use and customize, so anyone interested in creating digital products will highly benefit from this feature!
    <br/>
    h3:No need to start from scratch — use Uizard's pre-made form component template to design your project
    <br/>
    Instead of starting from scratch or using a text-only template, you can use Uizard's ready-made form component template to build your app or website.
    Choose any form that you like and customize it to match your chosen look and feel.
    "
    pages={[
      "Settings form",
      "Settings",
      "Sign up form",
      "Header hero signup",
      "Chat bot support",
      "Subscribe to newsletter pop up",
      "Subscribe to newsletter",
      "Shipping form",
      "Dialog card with input fields",
      "Login form",
    ]}
    img1={data.image1.childImageSharp}
    img1alt="Screenshot showcasing the component template for designing forms"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot showcasing the component template for designing forms"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot showcasing the component template for designing forms"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot showcasing the component template for designing forms"
    img5={data.image5.childImageSharp}
    img5alt="Screenshot showcasing the component template for designing forms"
  />
);

export default ComponentTemplate;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/component-templates/form/form_1.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/component-templates/form/form_2.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/component-templates/form/form_3.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/component-templates/form/form_4.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/component-templates/form/form_5.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
